<template>
  <v-layout class="column sheet-form">
    <div class="form-main-header">
      <h1>
        {{ formName }}
      </h1>
      <v-divider />
    </div>
    <slot name="top" />
    <v-form ref="classification" class="customer-account">
      <div class="inner-container">
        <CRInput
          id="classification-input"
          v-model="model.label"
          :label="'Classification Name'"
          :disabled="isModeView"
          :rules="[
            isRequired(true, isNotEmpty, {
              req: 'Classification Name Is Required',
              error: 'Classification Name Is Required',
            }),
          ]"
        />
        <CRSelect
          id="classification-type-select"
          v-model="model.classificationTypeId"
          :label="'Classification Type'"
          :disabled="isModeView"
          item-value="id"
          item-text="label"
          placeholder="Select a classification type"
          solo
          flat
          :items="classificationTypes"
          :rules="[
            isRequired(true, isNotEmpty, {
              req: 'Classification Type is Required',
              error: 'Classification Type Is Required',
            }),
          ]"
          @change="getAvailableClassifications"
        />
        <CRSelect
          id="parent-classification-select"
          v-model="model.parentId"
          :label="'Parent Classification'"
          item-value="classificationId"
          item-text="fullLabel"
          :disabled="isModeView"
          placeholder="Select a parent classification (optional)"
          solo
          flat
          :items="availableParentClassifications"
        />

        <div class="button-container">
          <v-btn
            v-show="!isModeView"
            id="classification-form-button-cancel"
            class="btn-secondaryaction"
            @click="cancelAction"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="isModeView"
            id="industries-form-button-edit"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="enableEdit"
          >
            Edit Classification
          </v-btn>
          <v-btn
            v-if="isModeAdd || isModeEdit"
            id="industries-form-button-save"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            {{ isModeAdd ? 'Add' : 'Save' }} Classification
          </v-btn>
        </div>
      </div>
    </v-form>
  </v-layout>
</template>
<script>
import classifications from '@/services/classifications'
import { authComputed } from '@/state/helpers'
import * as logger from '@/utils/logger'
import { sort } from '@/utils/sort'
import { filter } from '@/utils/filter'
import { isRequired, isNotEmpty } from '@/utils/validators'

export default {
  props: {
    id: {
      type: String,
      default: () => null,
    },
    classificationTypeId: {
      type: String,
      default: () => null,
    },
    mode: {
      type: String,
      default: () => 'view',
    },
  },
  data() {
    return {
      model: {
        label: '',
        parentId: undefined,
        classificationTypeId: null,
        isOther: false,
      },
      loading: false,
      availableParentClassifications: [],
      classificationTypes: [],
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    formName() {
      if (this.isModeEdit) {
        return 'Edit Classification'
      }
      if (this.isModeView) {
        return 'View Classification'
      }
      if (this.isModeAdd) {
        return 'Add Classification'
      }

      return 'Classification'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeView() {
      return this.mode === 'view'
    },
  },
  watch: {
    $route: async function (to, from) {
      if (to.path === from.path) {
        return
      }
      if (
        to.params.id?.toString() === from.params.id?.toString() &&
        !to.params?.subRoute
      ) {
        return
      }
      await this.loadClassification()
      this.getAvailableClassifications()
    },
  },
  async mounted() {
    this.getClassificationTypes()
    if (this.isModeEdit || this.isModeView) {
      await this.loadClassification()
    }
    this.getAvailableClassifications()
  },
  methods: {
    async loadClassification() {
      let classificationData
      classificationData = await classifications.getRefundClassificationDetail(
        this.id
      )
      if (!classificationData) {
        classificationData = await classifications.getCancellationClassificationDetail(
          this.id
        )
      }
      if (!classificationData) {
        classificationData = await classifications.getChargeClassificationDetail(
          this.id
        )
      }
      classificationData = classificationData?.data?.classification
      this.model.classificationId = this.id
      this.model.label = classificationData.label
      this.model.parentId =
        classificationData?.parentClassificationDTO?.classificationId
      this.model.classificationTypeId =
        classificationData?.classificationTypeDTO?.id
      this.model.isOther = classificationData.isOther
    },
    async getClassificationTypes() {
      const classificationTypeData = await this.$store.dispatch(
        'types/getClassificationTypes'
      )
      this.classificationTypes = classificationTypeData.data
    },
    async getAvailableClassifications() {
      const classificationSort = sort()
      const filterUtil = filter()
      classificationSort.add({
        prop: 'classificationId',
        direction: 'asc',
      })

      if (this.model.classificationTypeId) {
        const classificationTypeFilter = {
          column: {
            _t_id: 'a02802d5-aab1-4ffa-8dde-69054bf6fd56',
            prop: 'classificationTypeId',
            filter: true,
            type: 'number',
            filterType: 'eq',
          },
          value: this.model.classificationTypeId,
        }
        const parentFilter = filterUtil.createParent('and')
        filterUtil.add(parentFilter, classificationTypeFilter)
      }

      const sortParams = classificationSort.asQueryParams()
      const filterParams = filterUtil.asQueryParams()
      const availableClassificationsData = await classifications
        .getClassifications({
          page: 1,
          pageSize: -1,
          sorts: sortParams,
          filters: filterParams,
        })
        .then((data) => data.data)
      this.availableParentClassifications = availableClassificationsData.resultList.map(
        (acd) => {
          const fullLabel =
            acd.parentClassifications === null
              ? acd.classificationName
              : acd.parentClassifications + ' > ' + acd.classificationName
          return {
            ...acd,
            fullLabel: fullLabel,
          }
        }
      )
      const emptyClassification = {
        classificationId: null,
        fullLabel: '',
      }
      this.availableParentClassifications.unshift(emptyClassification)
    },
    async submit() {
      let id
      this.loading = true

      const formIsValid = this.$refs.classification.validate()
      if (!formIsValid) {
        this.loading = false
        return
      }

      try {
        if (this.isModeAdd) {
          if (!this.model.parentId) {
            this.model.parentId = null
          }
          const classificationData = await classifications.createClassification(
            this.model
          )
          id = classificationData?.data?.classification?.classificationId
        }

        if (this.isModeEdit) {
          await classifications.updateClassification(this.model)
        }
      } catch (e) {
        logger.error(e)
      }

      this.loading = false
      this.$router.push(`/classifications/view/${this.id || id}`)
      this.$store.dispatch('app/showAlert', {
        message: 'Classification saved.',
        type: 'success',
      })
    },
    backToTable() {
      this.$router.push({ name: 'classifications' })
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disableAndRevert()
        return
      }

      this.backToTable()
    },
    enableEdit() {
      if (!this.isModeEdit) {
        this.$router.push(`/classifications/edit/${this.id}`)
      }
    },
    disableAndRevert() {
      this.$router.push(`/classifications/view/${this.id}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  justify-content: center;
}

.customer-account {
  display: flex;
  justify-content: center;

  .inner-container {
    width: 50%;

    .spacing {
      margin-top: 30px;
      margin-bottom: 5%;
    }

    .justify-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
