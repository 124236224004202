var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"column sheet-form"},[_c('div',{staticClass:"form-main-header"},[_c('h1',[_vm._v(" "+_vm._s(_vm.formName)+" ")]),_c('v-divider')],1),_vm._t("top"),_c('v-form',{ref:"classification",staticClass:"customer-account"},[_c('div',{staticClass:"inner-container"},[_c('CRInput',{attrs:{"id":"classification-input","label":'Classification Name',"disabled":_vm.isModeView,"rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Classification Name Is Required',
            error: 'Classification Name Is Required',
          }),
        ]},model:{value:(_vm.model.label),callback:function ($$v) {_vm.$set(_vm.model, "label", $$v)},expression:"model.label"}}),_c('CRSelect',{attrs:{"id":"classification-type-select","label":'Classification Type',"disabled":_vm.isModeView,"item-value":"id","item-text":"label","placeholder":"Select a classification type","solo":"","flat":"","items":_vm.classificationTypes,"rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Classification Type is Required',
            error: 'Classification Type Is Required',
          }),
        ]},on:{"change":_vm.getAvailableClassifications},model:{value:(_vm.model.classificationTypeId),callback:function ($$v) {_vm.$set(_vm.model, "classificationTypeId", $$v)},expression:"model.classificationTypeId"}}),_c('CRSelect',{attrs:{"id":"parent-classification-select","label":'Parent Classification',"item-value":"classificationId","item-text":"fullLabel","disabled":_vm.isModeView,"placeholder":"Select a parent classification (optional)","solo":"","flat":"","items":_vm.availableParentClassifications},model:{value:(_vm.model.parentId),callback:function ($$v) {_vm.$set(_vm.model, "parentId", $$v)},expression:"model.parentId"}}),_c('div',{staticClass:"button-container"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isModeView),expression:"!isModeView"}],staticClass:"btn-secondaryaction",attrs:{"id":"classification-form-button-cancel"},on:{"click":_vm.cancelAction}},[_vm._v(" Cancel ")]),(_vm.isModeView)?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":"industries-form-button-edit","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.enableEdit}},[_vm._v(" Edit Classification ")]):_vm._e(),(_vm.isModeAdd || _vm.isModeEdit)?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":"industries-form-button-save","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.isModeAdd ? 'Add' : 'Save')+" Classification ")]):_vm._e()],1)],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }